<!--
 * @Author: fang
 * @Date: 2024-12-10 10:46:23
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-13 10:41:13
 * @FilePath: /web_artemisads/src/artemisadsComponents/artmisadsSelectButton.vue
 * @Description: 
-->
<template>
  <a-button :class="className" v-bind="props">
    <artemisads-icon v-if="props.iconName" :name="props.iconName" style="vertical-align: -0.08em; font-size: 20px;" />
    <svg class="icon artmisads-select-filter-icon" aria-hidden="true" v-if="props.showFilterIcon">
      <use xlink:href="#icon-guolv"></use>
    </svg>
    <svg class="icon artmisads-select-edit-icon" aria-hidden="true" v-if="props.showEditIcon">
      <use xlink:href="#icon-bianji"></use>
    </svg>
    <slot></slot>
    <span v-if="props.filterNum" class="num">{{props.filterNum}}</span>
    <svg class="icon artmisads-select-down-icon" aria-hidden="true" v-if="props.showDownIcon">
      <use xlink:href="#icon-jiantou-xia-cu"></use>
    </svg>
    <svg class="icon artmisads-next-icon" aria-hidden="true" v-if="props.showNextIcon">
      <use xlink:href="#icon-jiantou-you-cu"></use>
    </svg>
  </a-button>
</template>
<script setup>
  import { computed } from 'vue';

  const defaultSize = 'middle'; // large small

  const props = defineProps(['size', 'showDownIcon', 'showFilterIcon', 'showNextIcon', 'filterNum', 'iconName', 'showEditIcon']);

  const className = computed(() => {
    let buttonClass = 'artmisads-select-button'
    if (props.size) {
      buttonClass += ` ${props.size}`;
    } else {
      buttonClass += ` ${defaultSize}`;
    }
    return buttonClass;
  })

</script>
<style lang="less" scoped>
  .ant-btn.artmisads-select-button {
    border-radius: 8px;
    text-align: center;
    line-height: 20px;
    font-weight: 500;
    height: var(--input-height-middle);
    font-size: var(--font-size-mormal);
    padding: 5px 12px 7px 12px;
    color: var(--dark-2);
    border: var(--border);
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    box-sizing: border-box;
    .artmisads-select-down-icon {
      margin-left: 12px;
      width: 14px;
      height: 14px;
      vertical-align: -3px;
      fill: var(--dark-4);
    }
    .artmisads-next-icon {
      margin-left: 4px;
      width: 14px;
      height: 14px;
      vertical-align: -2px;
      fill: var(--dark-2);
    }
    .artmisads-select-edit-icon,
    .artmisads-select-filter-icon {
      margin-right: 8px;
      width: 20px;
      height: 20px;
      vertical-align: -5px;
      fill: var(--dark-2);
    }
    .num {
      margin-left: 4px;
      width: 20px;
      height: 18px;
      background: var(--yellow-1);
      border-radius: 4px;
      font-weight: 500;
      font-size: var(--font-size-mormal);
      color: var(--dark-1);
      text-align: center;
      line-height: 18px;
    }
    &.large {
      padding: 0 20px;
      font-size: 16px;
      line-height: 40px;
      border-radius: 8px;
      height: var(--input-height-large);
    }
    &.small {
      height: var(--input-height-small);
      line-height: 18px;
      font-size: var(--font-size-small);
      padding: 2px 12px 4px 12px;
      border-radius: 6px;
    }
    &:hover {
      border-color: var(--bg-gray-7);
      background-color: var(--bg-gray-1);
      color: var(--dark-1);
    }
    &:active {
      background-color: var(--bg-gray-2);
      color: var(--dark-1);
      border-color: var(--bg-gray-7);
    }
    &:disabled{
      border: 1px solid var(--border-1);
      background:var(--disabled-fill-1) ;
      color: var(--dark-3);
    }
    &.ant-dropdown-open {
      border: 1px solid var(--primary-border);
      box-shadow: var(--section-box-shadow-4);
      .artmisads-select-down-icon {
        fill: var(--dark-4);
      }
    }
    &.ant-popover-open {
      border: 1px solid var(--primary-border);
      box-shadow: var(--section-box-shadow-4);
      .artmisads-select-filter-icon{
        fill: var(--dark-2);
      }
      .artmisads-select-down-icon {
        fill: var(--dark-4);
      }
    }

  }
</style>
