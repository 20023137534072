<template>
  <div class="pagination">
    <a-pagination
      v-model:current="props.pagination.current"
      v-bind="props.pagination"
      @change="handlePageChange"
    >
      <template #itemRender="{ type, originalElement }">
        <a v-if="type === 'prev'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou-zuo-cu"></use>
          </svg>
        </a>
        <a v-else-if="type === 'next'">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiantou-you-cu"></use>
          </svg>
        </a>
        <component :is="originalElement" v-else></component>
      </template>
      <template #buildOptionText="props">
        <span>{{ props.value }} per page</span>
      </template>
    </a-pagination>
  </div>
</template>
<script setup>

const props = defineProps(["pagination", 'change']);

const handlePageChange = (page, pageSize) => {
  let pagination = { current: page, pageSize };
  props.change(pagination);
};
</script>
<style lang="less" scoped>
@import '../assets/less/pagination.less';

</style>