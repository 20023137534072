/*
 * @Author: Gang Jiang 
 * @Date: 2024-09-18 11:01:35 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-03-04 10:49:00
 */

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router/router'
import DatePicker from 'ant-design-vue/es/date-picker/moment';
import TimePicker from 'ant-design-vue/es/time-picker/moment';
import Calendar from 'ant-design-vue/es/calendar/moment';
import moment from 'moment-timezone';
import { notification } from 'ant-design-vue';
import '~/assets/images/svg/index.js';
import { COUNTRY_TIME_TZ } from './contants/common';

import artemisadsComponents from './artemisadsComponents'
import './assets/less/common.less'
import './assets/less/antd-theme.less'
import './assets/iconfont/iconfont.js'
import Cooperators from '~/contants/cooperator.js'
// import "ant-design-vue/dist/antd.css"
const app = createApp(App);
const pinia = createPinia();

const localStoragePrefix = location.hostname;

// 检查是否有token、token是否过期
const checkUserStatus = () => {
  let isActive = true;
  const userToken = localStorage.getItem(`${localStoragePrefix}_userToken`);
  const tokenTime = Number(localStorage.getItem(`${localStoragePrefix}_tokenTime`));
  const timeNow = new Date().getTime();
  const expirationTime = 1000 * 60 * 60 * 24 * 3; //3天
  if (!userToken || (tokenTime + expirationTime <= timeNow)) {
    localStorage.setItem(`${localStoragePrefix}_userToken`, '');
    localStorage.setItem(`${localStoragePrefix}_tokenTime`, '');
    localStorage.setItem(`${localStoragePrefix}_artmisAdsUserInfo`, '');
    isActive = false;
  }
  return isActive;
}

function isCooperator(host) {
  const cooperatorName = host.replace(/^(\w+)\.[\w\.]*/, '$1')
  const cooperator = Cooperators[cooperatorName]

  return !!cooperator
}

router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title : 'Artemisads';
  
  if (to.matched.length) {
    let userInfo = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);

    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    } else {
      userInfo = {};
    }

    let nextUrl = '';

    if ('/products' === to.path || /\/help/.test(to.path) || ['about', 'services', 'clients'].map(item => '/?point=' + item).indexOf(to.fullPath) != -1) {
      // 帮助中心、cooperator不需要登录验证
      next()
    } else if (isCooperator(location.host)) {
      next('/products')
    } else if (to.meta.requiresAUserType) { // 判断该路由是否需要进行鉴权
      if (to.meta.requiresAUserType === userInfo.userType) {
        const isActive = checkUserStatus();
        if (!isActive) {
          if (userInfo.userType === 'admin') {
            nextUrl = '/operation/login'
          } else {
            nextUrl = '/sign-in'
          }
        } else if (userInfo.status === 'PENDING_CHOOSE_TYPE') {
          nextUrl = '/profile'
        }
      } else {
        nextUrl = from.path;
      }

      if (nextUrl) {
        next(nextUrl);
      } else {
        next();
      }
    } else {
      if (from.path === '/' && userInfo.userType && userInfo.reviewStatus !== 'Rejected') {
        if (userInfo.userType === 'publisher') {
          if (userInfo.reviewStatus === 'Rejected') {
            nextUrl = '/profile'
          } else {
            nextUrl = '/publisher/dashboard'
          }
        } else if (userInfo.userType === 'seller') {
          nextUrl = '/seller/dashboard'
        } else {
          nextUrl = '/operator/users'
        }
      }

      if (from.path === '/' && userInfo.reviewStatus === 'Rejected' && to.path != '/profile') {
        nextUrl = '/profile'
      }
      
      if (nextUrl) {
        next(nextUrl);
      } else {
        next(); // 不需要进行鉴权，直接进行下一步路由
      }
    }
  } else {
    next('/404');
  }
});

notification.config({
  placement: 'bottomRight',
  bottom: '50px',
  duration: 3,
  rtl: true,
});

// 设置时区
let defaultCountry = 'US';
let userInfoData = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);
if (userInfoData) {
  userInfoData = JSON.parse(userInfoData);
  defaultCountry = userInfoData.countryCode || 'US';
}
moment.tz.setDefault(COUNTRY_TIME_TZ[defaultCountry]);

app.config.productionTip = false;
app.use(router).use(Antd).use(DatePicker).use(TimePicker).use(Calendar).use(pinia).use(artemisadsComponents).mount('#app');

