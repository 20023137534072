export const CURRENCY_MAPPER = {
  US: {
    host: 'amazon.com',
    currency: '$',
    timezone: 'America/Los_Angeles'
  },
  UK: {
    host: 'amazon.co.uk',
    currency: '£',
    timezone: 'GMT'
  },
  DE: {
    host: 'amazon.de',
    currency: '€',
    timezone: 'CET'
  },
  CA: {
    host: 'amazon.ca',
    currency: 'CA$',
    timezone: 'America/Los_Angeles'
  },
  FR: {
    host: 'amazon.fr',
    currency: '€',
    timezone: 'CET'
  },
}
export const COUNTRY_BASE = [
  {
    countryCode:'US',
    host: 'amazon.com',
    currency: '$',
    icon:'guoqi-us',
  },
  {
    countryCode:'UK',
    host: 'amazon.co.uk',
    currency: '£',
    icon:'guoqi-uk',
  },
  {
    countryCode:'DE',
    host: 'amazon.de',
    currency: '€',
    icon:'guoqi-de',
  },
  {
    countryCode:'CA',
    host: 'amazon.ca',
    currency: 'CA$',
    icon:'guoqi-ca',
  },
  {
    countryCode:'FR',
    host: 'amazon.fr',
    currency: '€',
    icon:'guoqi-fr',
  },
]

export const COUNTRY_SITE_CODE_MAP = {
  'amazon.com': 'US',
  'amazon.co.uk': 'UK',
  'amazon.de': 'DE',
  'amazon.ca': 'CA',
  'amazon.fr': 'FR',
}

export const AMAZON_CURRENCY_MAPPER = {
  'amazon.com' :'$',
  'amazon.co.uk':'£',
  'amazon.de': '€',
  'amazon.ca':  'CA$',
  'amazon.fr': '€',
}
export const COUNTRY_TIME_TZ = {
  'US': 'America/Los_Angeles',
  'UK': 'Etc/GMT',
  'DE': 'Europe/Berlin',
  'FR': 'Europe/Paris',
  'CA': 'America/Los_Angeles',
}

export const AMAZON_COUNTRY_TIME_TZ = {
  'amazon.com': 'America/Los_Angeles',
  'amazon.co.uk': 'Etc/GMT',
  'amazon.de': 'Europe/Berlin',
  'amazon.fr': 'Europe/Paris',
  'amazon.ca': 'America/Los_Angeles',
}

export const COUNTRY_COMMISSION_TXT = {
  'US': "Commissions are locked for a given day at 11:59 pm Pacific Time (PT) the night before. The current commission value will become tomorrow's locked-in commission value at that time. ",
  'UK': "Commissions are locked for a given day at 11:59 pm Greenwich Mean Time Zone (GMT) the night before. The current commission value will become tomorrow's locked-in commission value at that time. ",
  'DE': "Commissions are locked for a given day at 11:59 pm Central European Time Zone (CET) the night before. The current commission value will become tomorrow's locked-in commission value at that time. ",
  'FR': "Commissions are locked for a given day at 11:59 pm Central European Time Zone (CET) the night before. The current commission value will become tomorrow's locked-in commission value at that time. ",
  'CA': "Commissions are locked for a given day at 11:59 pm Pacific Time (PT) the night before. The current commission value will become tomorrow's locked-in commission value at that time. ",
}