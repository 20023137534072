/*
 * @Author: Gang Jiang 
 * @Date: 2025-02-13 15:53:53 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-02-14 13:51:22
 */
export default {
  partnerize: {
    // public目录存放cooperator logo图片
    logo: 'cooperator/partnerize.png'
  }
}