import HelpCenter from './helpCenter'
import { createRouter, createWebHistory } from 'vue-router'
import { operatorRoute } from './operator';

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import('../pages/home/index.vue'),
      children: [
        {
          path: "/404",
          name: "404",
          component: () => import('../pages/404.vue'),
          meta: {
            title: 'Not Found - ArtemisAds'
          }
        }
      ],
    },
    {
      path: "/seller",
      name: "seller",
      redirect: '/seller/product',
      component: () => import('../pages/seller/index.vue'),
      meta: {
        requiresAUserType: 'seller'
      },
      children: [
        {
          path: 'pay-result',
          name: 'seller-pay-result',
          component: () => import('../pages/seller/payResult/index.vue'),
          meta: {
            title: 'Pay Result - ArtemisAds',
            hiddenNav: true
          }
        },
        {
          path: 'invoice-pdf/:id',
          name: 'seller-invoice-pdf',
          component: () => import('../pages/seller/pdf.vue'),
          meta: {
            title: 'Finance Invoice PDF - ArtemisAds',
          }
        },
        {
          path: "finance",
          name: "seller finance",
          redirect: '/seller/finance/payments',
          meta: {
            title: 'Finance - ArtemisAds',
          },
          children: [
            {
              path: "payments",
              name: "seller finance payments",
              component: () => import('../pages/seller/finance/payments/home/index.vue'),
              meta: {
                title: 'Finance Payments - ArtemisAds',
              }
            },
          ]
        },
        {
          path: "reports",
          name: "seller reports",
          redirect: '/seller/reports/performance',
          meta: {
            title: 'Reports - ArtemisAds',
          },
          children: [
            {
              path: "performance",
              name: "seller reports performance",
              component: () => import('../pages/seller/reports/performance/index.vue'),
              meta: {
                title: 'Performance Reports - In ArtemisAds',
              }
            },
            {
              path: "brand",
              name: "seller reports brand",
              component: () => import('../pages/seller/reports/brand/index.vue'),
              meta: {
                title: 'Brand Reports - In ArtemisAds',
              }
            },
            {
              path: "product",
              name: "seller reports product",
              component: () => import('../pages/seller/reports/product/index.vue'),
              meta: {
                title: 'Product Reports - In ArtemisAds',
              }
            },
            {
              path: "publisher",
              name: "seller reports publisher",
              component: () => import('../pages/seller/reports/publisher/index.vue'),
              meta: {
                title: 'Publisher Reports - In ArtemisAds',
              }
            },
          ]
        },
        {
          path: "dashboard",
          name: "seller dashboard",
          component: () => import('../pages/seller/dashboard/index.vue'),
          meta: {
            title: 'Dashboard - ArtemisAds',
          }
        },
        // {
        //   path: "welcome",
        //   name: "welcome",
        //   component: () => import('../pages/seller/dashboard/index.vue'),
        //   meta: {
        //     title: 'Welcome - ArtemisAds',
        //   }
        // },
        {
          path: "product",
          name: "product",
          component: () => import('../pages/seller/product/index.vue'),
          meta: {
            title: 'Product - ArtemisAds',
            namePath: ['product'],
            label: 'Products'
          }
        },
        {
          path: 'brand',
          name: "brand",
          redirect: '/seller/brand/index',
          meta: {
            title: 'Brands - ArtemisAds',
            namePath: ['brand'],
            label: 'Brands'
          },
          children: [
            {
              path: 'index',
              name: 'brandList',
              component: () => import('../pages/seller/Brands/index.vue'),
              meta: {
                namePath: ['brand', 'brandList'],
                label: 'Brands',
                isNotShowMenu: true
              }
            },
            {
              path: "detail/:id",
              name: "brandDetail",
              component: () => import('../pages/seller/Brands/detail.vue'),
              meta: {
                namePath: ['brand', 'brandDetail'],
                label: 'Brand Name'
              }
            }
          ]
        },
        {
          path: "product/:id",
          name: "Seller Product Detail",
          component: () => import('../pages/seller/product/detail/index.vue'),
          meta: {
            title: 'Product - ArtemisAds',
          }
        },
        {
          path: 'connection',
          name: 'sl-connection',
          component: () => import('../pages/seller/Settings/connections.vue'),
          meta: {
            title: 'Connections - ArtemisAds',
          }
        },
        {
          path: 'account-info',
          name: 'sl-account-info',
          component: () => import('../pages/seller/Settings/accountInfo/index.vue'),
          meta: {
            title: 'Account Info - ArtemisAds',
          }
        },
        {
          path: 'commission-rule',
          name: 'sl-commission-rule',
          component: () => import('../pages/seller/Settings/commissionRule/index.vue'),
          meta: {
            title: 'Commission Rule - ArtemisAds',
          }
        },
        {
          path: "referral",
          name: 'Referral  - ArtemisAds',
          component: () => import('../pages/referral/index.vue'),
          meta: {
            title: 'Referral - ArtemisAds',
          }
        },
      ],
    },
    
   
    // {
    //   path: "/mail",
    //   name: "/mail",
    //   component: () => import('../pages/mailTemplate/mail.vue'),
    //   meta: {
    //     title: 'mail - ArtemisAds',
    //   }
    // },
    // {
    //   path: "/publisher-rejected-email",
    //   name: "/publisher-rejected-email",
    //   component: () => import('../pages/mailTemplate/publisher-rejected-email.vue'),
    //   meta: {
    //     title: 'mail - ArtemisAds',
    //   }
    // },
    // {
    //   path: "/publisher-approved-email",
    //   name: "/publisher-approved-email",
    //   component: () => import('../pages/mailTemplate/publisher-approved-email.vue'),
    //   meta: {
    //     title: 'mail - ArtemisAds',
    //   }
    // },
    {
      path: "/terms-of-service",
      name: "/Terms Of Service",
      component: () => import('../pages/termsOfService/index.vue'),
      meta: {
        title: 'Terms Of Service - ArtemisAds',
      }
    },
    {
      path: "/privacy-policy",
      name: "/Privacy Policy",
      component: () => import('../pages/privacyPolicy/index.vue'),
      meta: {
        title: 'Privacy Policy - ArtemisAds',
      }
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: () => import('../pages/signIn/index.vue'),
      meta: {
        title: 'Sign In - ArtemisAds',
      }
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: () => import('../pages/signUp/index.vue'),
      meta: {
        title: 'Sign Up - ArtemisAds'
      }
    },
    {
      path: "/find-password",
      name: "find-password",
      component: () => import('../pages/forget/index.vue'),
      meta: {
        title: 'Find password - ArtemisAds'
      }

    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import('../pages/forget/resetPassword.vue'),
      meta: {
        title: 'Reset password - ArtemisAds'
      }

    },
    {
      path: "/profile",
      name: "profile",
      component: () => import('../pages/profile/index.vue'),
      meta: {
        title: 'Profile - ArtemisAds',
      }
    },
    {
      path: "/publisher",
      name: "Publisher",
      redirect: '/publisher/products',
      component: () => import('../pages/publisher/index.vue'),
      meta: {
        requiresAUserType: 'publisher'
      },
      children: [
        {
          path: 'invoice-pdf/:id',
          name: 'publisher-invoice-pdf',
          component: () => import('../pages/publisher/pdf.vue'),
          meta: {
            title: 'Finance Invoice PDF - ArtemisAds',
          }
        },
        {
          path: "finance",
          name: "publisher finance",
          redirect: '/publisher/finance/payments',
          meta: {
            title: 'Finance - ArtemisAds',
          },
          children: [
            {
              path: "payments",
              name: "publisher finance payments",
              component: () => import('../pages/publisher/finance/payments/home/index.vue'),
              meta: {
                title: 'Finance Payments - ArtemisAds',
              }
            },
          ]
        },
        {
          path: "dashboard",
          name: "publisher dashboard",
          component: () => import('../pages/publisher/dashboard/index.vue'),
          meta: {
            title: 'Dashboard - ArtemisAds',
          }
        },
        {
          path: "reports",
          name: "publisher reports",
          redirect: '/publisher/reports/performance',
          meta: {
            title: 'Reports - In ArtemisAds',
          },
          children: [
            {
              path: "performance",
              name: "publisher reports performance",
              component: () => import('../pages/publisher/reports/performance/index.vue'),
              meta: {
                title: 'Performance Reports - In ArtemisAds',
              }
            },
            {
              path: "brand",
              name: "publisher reports brand",
              component: () => import('../pages/publisher/reports/brand/index.vue'),
              meta: {
                title: 'Brand Reports - In ArtemisAds',
              }
            },
            {
              path: "product",
              name: "publisher reports product",
              component: () => import('../pages/publisher/reports/product/index.vue'),
              meta: {
                title: 'Product Reports - In ArtemisAds',
              }
            }
          ]
        },
        {
          path: "products",
          name: "Products",
          component: () => import('../pages/publisher/products/index.vue'),
          meta: {
            title: "Products - Artemis Ads",
          }
        },
        {
          path: "brands",
          name: "Brands",
          component: () => import('../pages/publisher/brands/index.vue'),
          meta: {
            title: "Brands - Artemis Ads",
          }
        },
        {
          path: "links",
          name: "Links",
          component: () => import('../pages/publisher/links/index.vue'),
          meta: {
            title: 'My Product Links - Artemis Ads'
          }
        },
        {
          path: "products/:id",
          name: "Product Detail",
          component: () => import('../pages/publisher/products/detail/index.vue'),
          meta: {
            title: "Product Detail - Artemis Ads",
          }
        },
        {
          path: "brands/:id",
          name: "Brand Detail",
          component: () => import('../pages/publisher/brands/detail.vue'),
          meta: {
            title: "Brand Detail - ArtemisAds",
          }
        },
        {
          path: "settings",
          name: "Settings - ArtemisAds",
          meta: {
            title: "Settings - ArtemisAds"
          },
          children: [
            {
              path: "profile",
              name: "Profile - Artemis Ads",
              component: () => import('../pages/publisher/settings/profile/index.vue'),
              meta: {
                title: 'Settings Profile - ArtemisAds',
              }
            },
            {
              path: "open-api",
              name: "Open API - Artemis Ads",
              component: () => import('../pages/publisher/settings/openAPI/index.vue'),
              meta: {
                title: 'Settings "Open API - ArtemisAds',
              }
            },
          ]
        },
        {
          path: "referral",
          name: 'Referral - ArtemisAds',
          component: () => import('../pages/referral/index.vue'),
          meta: {
            title: 'Referral - ArtemisAds',
          }
        },
      ]
    },
    {
      // cooperator路由
      path: "/products",
      name: "products",
      component: () => import('../pages/public/cooperator/index.vue'),
      meta: {
        title: 'Management Platform - ArtemisAds',
      }
    },
    operatorRoute,
    {
      path: "/operator/login",
      name: "op_login",
      component: () => import('../pages/operator/login/index.vue'),
      meta: {
        title: 'Login - ArtemisAds Op',
      }
    },
    { ...HelpCenter },
  ],
})