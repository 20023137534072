/*
 * @Author: Gang Jiang 
 * @Date: 2025-02-19 10:52:28 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-03-03 10:50:04
 */

export default {
  path: "/help",
  name: 'Help Center - ArtemisAds',
  redirect: '/help/about-artemis-ads',
  component: () => import('../pages/public/help/index.vue'),
  meta: {
    title: 'Help Center - ArtemisAds',
  },
  children: [
    {
      path: "about-artemis-ads",
      name: 'About Artemis Ads - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/about.vue'),
      meta: {
        title: 'About Artemis Ads | ArtemisAds',
      }
    },
    {
      path: "why-join-artemis-ads",
      name: 'Why Join Artemis Ads? - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/why-join-artemis-ads.vue'),
      meta: {
        title: 'Why Join Artemis Ads? | ArtemisAds',
      }
    },
    {
      path: "how-to-join-artemis-ads-as-a-brand",
      name: 'How to Join Artemis Ads as a Brand? - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/how-to-join-artemis-ads-as-a-brand.vue'),
      meta: {
        title: 'How to Join Artemis Ads as a Brand? | ArtemisAds',
      }
    },
    {
      path: "artemis-ads-pricing-structure",
      name: 'Artemis Ads Pricing Structure - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/artemis-ads-pricing-structure.vue'),
      meta: {
        title: 'Artemis Ads Pricing Structure | ArtemisAds',
      }
    },
    {
      path: "attribution-data-access-authorization",
      name: 'Attribution Data Access Authorization - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/attribution-data-access-authorization.vue'),
      meta: {
        title: 'Attribution Data Access Authorization | ArtemisAds',
      }
    },
    {
      path: "earning-amazon-brand-referral-bonus",
      name: 'Earn the Amazon Brand Referral Bonus - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/earning-amazon-brand-referral-bonus.vue'),
      meta: {
        title: 'Earn the Amazon Brand Referral Bonus | ArtemisAds',
      }
    },
    {
      path: "brand-referral-bonus-rates-by-category",
      name: 'Brand Referral Bonus Rates by Category - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/brand-referral-bonus-rates-by-category.vue'),
      meta: {
        title: 'Brand Referral Bonus Rates by Category | ArtemisAds',
      }
    },
    {
      path: "amazon-attribution-model",
      name: 'Amazon Attribution Model - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/amazon-attribution-model.vue'),
      meta: {
        title: 'Amazon Attribution Model | ArtemisAds',
      }
    },
    {
      path: "amazon-attribution-and-sales-reporting",
      name: 'Amazon Attribution and Sales Reporting - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/amazon-attribution-and-sales-reporting.vue'),
      meta: {
        title: 'Amazon Attribution and Sales Reporting | ArtemisAds',
      }
    },
    {
      path: "promotion-requirements-on-artemis-ads",
      name: 'Promotion Requirements on Artemis Ads - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/promotion-requirements-on-artemis-ads.vue'),
      meta: {
        title: 'Promotion Requirements on Artemis Ads | ArtemisAds',
      }
    },
    {
      path: "multi-brand-and-multi-product-promotions",
      name: 'Multi-Brand and Multi-Product Promotions - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/multi-brand-and-multi-product-promotions.vue'),
      meta: {
        title: 'Multi-Brand and Multi-Product Promotions | ArtemisAds',
      }
    },
    {
      path: "where-will-promotion-posts-be-displayed",
      name: 'Where Will Promotion Posts Be Displayed? - ArtemisAds',
      component: () => import('../pages/public/help/about-artemis-ads/where-will-promotion-posts-be-displayed.vue'),
      meta: {
        title: 'Where Will Promotion Posts Be Displayed? | ArtemisAds',
      }
    },
    // for seller
    {
      path: "for-seller",
      name: 'For Seller - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/index.vue'),
      meta: {
        title: 'For Seller | ArtemisAds',
      }
    },
    {
      path: "seller-general-account-setup",
      name: 'Seller General Account Setup - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/seller-general-account-setup.vue'),
      meta: {
        title: 'Seller General Account Setup | ArtemisAds',
      }
    },
    {
      path: "activate-brands",
      name: 'Activate Brand(s) - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/activate-brand(s).vue'),
      meta: {
        title: 'Activate Brand(s) | ArtemisAds',
      }
    },
    {
      path: "activate-products",
      name: 'Activate Product(s) - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/activate-product(s).vue'),
      meta: {
        title: 'Activate Product(s) | ArtemisAds',
      }
    },
    {
      path: "manage-multiple-sellers",
      name: 'Manage Multiple Sellers - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/manage-multiple-sellers.vue'),
      meta: {
        title: 'Manage Multiple Sellers | ArtemisAds',
      }
    },
    {
      path: "manage-deals-in-bulk",
      name: 'Manage Deals in Bulk - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/manage-deals-in-bulk.vue'),
      meta: {
        title: 'Manage Deals in Bulk | ArtemisAds',
      }
    },
    {
      path: "manage-products-in-bulk",
      name: 'Manage Products in Bulk - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/manage-products-in-bulk.vue'),
      meta: {
        title: 'Manage Products in Bulk | ArtemisAds',
      }
    },
    {
      path: "amazon-attribution-service",
      name: 'Amazon Attribution Service - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/amazon-attribution-service.vue'),
      meta: {
        title: 'Amazon Attribution Service | ArtemisAds',
      }
    },
    {
      path: "amazon-attribution-link",
      name: 'Amazon Attribution Link - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/amazon-attribution-link.vue'),
      meta: {
        title: 'Amazon Attribution Link | ArtemisAds',
      }
    },
    {
      path: "brand-referral-bonus",
      name: 'Brand Referral Bonus - ArtemisAds',
      component: () => import('../pages/public/help/for-seller/brand-referral-bonus.vue'),
      meta: {
        title: 'Brand Referral Bonus | ArtemisAds',
      }
    },
    // for publisher
    {
      path: "getting-started-with-artemis-ads",
      name: 'Getting Started with Artemis Ads - ArtemisAds',
      component: () => import('../pages/public/help/for-publisher/getting-started-with-artemis-ads.vue'),
      meta: {
        title: 'Getting Started with Artemis Ads | ArtemisAds',
      }
    },
    {
      path: "commissions-and-sales-tracking",
      name: 'Commissions and Sales Tracking - ArtemisAds',
      component: () => import('../pages/public/help/for-publisher/commissions-and-sales-tracking.vue'),
      meta: {
        title: 'Commissions and Sales Tracking | ArtemisAds',
      }
    },
    {
      path: "promotions-and-global-reach",
      name: 'Promotions and Global Reach - ArtemisAds',
      component: () => import('../pages/public/help/for-publisher/promotions-and-global-reach.vue'),
      meta: {
        title: 'Promotions and Global Reach | ArtemisAds',
      }
    },
    {
      path: "creating-engaging-and-trustworthy-content",
      name: 'Creating Engaging and Trustworthy Content - ArtemisAds',
      component: () => import('../pages/public/help/for-publisher/creating-engaging-and-trustworthy-content.vue'),
      meta: {
        title: 'Creating Engaging and Trustworthy Content | ArtemisAds',
      }
    },
    {
      path: "payments-and-support",
      name: 'Payments and Support - ArtemisAds',
      component: () => import('../pages/public/help/for-publisher/payments-and-support.vue'),
      meta: {
        title: 'Payments and Support | ArtemisAds',
      }
    },
  ]
}